import * as R from 'ramda'
import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import Autocomplete from '@material-ui/lab/Autocomplete'
import algoliasearch from 'algoliasearch/lite'
import {
  ClearRefinements,
  Configure,
  CurrentRefinements,
  RefinementList,
  Highlight,
  Hits,
  InstantSearch,
  connectHits,
  connectInfiniteHits,
  connectMenu,
  connectSearchBox,
  connectSortBy,
  connectRefinementList,
  connectToggleRefinement,
  ToggleRefinement
} from 'react-instantsearch-dom'

import SEO from '../components/SEO'
import Hero from '../components/Hero'
import Layout from '../components/Layout'

import { mapIndexed, nilOrEmpty, notNilOrEmpty } from '../lib/Helpers'

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}))

const names = [
  {
    name: 'EngageSpace Exhibitor',
    value: 'is_exhibitor'
  },
  {
    name: 'AFWERX Challenge Participant',
    value: 'afwerx_challenge_submission'
  },
  {
    name: 'SBIR Program',
    value: 'sbir_program'
  },
  {
    name: 'STTR Program',
    value: 'sttr_program'
  }
]

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

export default props => {
  const [selectedChallenge, setSelectedChallenge] = React.useState('')
  const [selectedRefine, setRefine] = React.useState([])

  const classes = useStyles()

  const Hit = ({ hits, hasPrevious, refinePrevious, hasMore, refineNext }) => {
    return (
      <>
        <section className="business-cards">
          {mapIndexed((hit, index) => (
            <div className="business-cards__single animated" key={index}>
              <div className="filtered-colors">
                {hit.afwerx_challenge_submission && (
                  <div className="filtered-colors__afwerx_challenge_submission" />
                )}
                {hit.is_exhibitor && (
                  <div className="filtered-colors__is_exhibitor" />
                )}
                {hit.sbir_program && (
                  <div className="filtered-colors__sbir_program" />
                )}
                {hit.sttr_program && (
                  <div className="filtered-colors__sttr_program" />
                )}
              </div>
              <div className="company_image">
                {notNilOrEmpty(hit.logo) && <img src={hit.logo} />}
              </div>
              <div className="company_data">
                <p className="company_data__title">
                  <Highlight attribute="title" hit={hit} />
                </p>
                <p className="company_data__desc">
                  <p className="company_data__contact">
                    <small>{hit.company_primary_contact}</small>
                  </p>
                  <p className="company_data__email">
                    <small>
                      Email:{' '}
                      <a href={`mailto:${hit.company_primary_contact_email}`}>
                        {hit.company_primary_contact_email}
                      </a>
                    </small>
                  </p>
                  <p className="company_data__phone">
                    <small>{hit.company_phone}</small>
                  </p>
                  <p className="company_data__url">
                    <small>
                      <a
                        href={hit.company_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {hit.company_url}
                      </a>
                    </small>
                  </p>
                  <Highlight attribute="description" hit={hit} />
                </p>
              </div>
            </div>
          ))(hits)}
        </section>
        {hasMore && (
          <div className="show-more-btn-container">
            <button className="btn btn--yellow" onClick={refineNext}>
              Show more
            </button>
          </div>
        )}
      </>
    )
  }

  const SearchBox = ({ currentRefinement, refine }) => (
    <TextField
      type="search"
      label="Search"
      variant="outlined"
      className="search"
      value={currentRefinement}
      onChange={event => refine(event.currentTarget.value)}
    />
  )

  const SortBy = props => {
    const { items, refine, createURL } = props
    return (
      <FormControl variant="outlined" className="filter-dropdown sort">
        <InputLabel id="sort-label">Sort</InputLabel>
        <Select
          label="Sort"
          labelId="sort-label"
          id="sort-select"
          defaultValue={props.defaultSort.value}
          onChange={e => refine(e.target.value)}
        >
          {mapIndexed((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          ))(items)}
        </Select>
      </FormControl>
    )
  }

  const MultiSelect = props => {
    const [checked, setChecked] = React.useState(false)
    const handleChange = event => {
      refine(!currentRefinement)
      setChecked(!checked)
    }
    const { currentRefinement, label, count, refine, createURL } = props
    return (
      <FormControlLabel
        classes={{ root: props.attribute }}
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            name={currentRefinement}
          />
        }
        label={label}
      />
    )
  }

  const MultiSelectOptions = connectToggleRefinement(MultiSelect)
  const CustomSearchBox = connectSearchBox(SearchBox)
  const BusinessCardHit = connectInfiniteHits(Hit)
  const CustomSortBy = connectSortBy(SortBy)

  return (
    <Layout>
      <SEO
        title={`EngageSpace Networking Directory | Afwerx Space Challenge`}
      />
      <Hero
        img={require('../assets/imgs/agenda-01.jpg')}
        className="challenge-type"
        title="EngageSpace Networking Directory"
      />
      <div className="container">
        <InstantSearch searchClient={searchClient} indexName="BusinessCards">
          <Configure
            distinct
            attributesToSnippet={['description:10']}
            snippetEllipsisText="…"
            removeWordsIfNoResults="allOptional"
            hitsPerPage={21}
          />
          <div className="filters filters--multiple-checkboxes">
            {mapIndexed((item, index) => (
              <MultiSelectOptions
                attribute={item.value}
                label={item.name}
                value={true}
              />
            ))(names)}
          </div>
          <section className="filters filters--business-cards">
            <CustomSortBy
              defaultRefinement="BusinessCards"
              currentRefinement="BusinessCards"
              defaultSort={{ value: 'BusinessCards', label: 'A - Z' }}
              items={[
                { value: 'BusinessCards', label: 'A - Z' },
                { value: 'BusinessCards_DESC', label: 'Z - A' }
              ]}
            />
            <CustomSearchBox />
          </section>
          <div
            className=""
            style={{ margin: '0 auto 50px', textAlign: 'center' }}
          >
            <AniLink
              cover
              to="/manage-networking-directory"
              duration={1.25}
              direction="right"
              className="btn btn--black"
              bg={`
              center / 50% /* position / size */
              no-repeat        /* repeat */
              fixed            /* attachment */
              padding-box      /* origin */
              content-box      /* clip */
              #00021a          /* color */
            `}
            >
              Add your information here
            </AniLink>
          </div>
          <BusinessCardHit />
        </InstantSearch>
      </div>
    </Layout>
  )
}
